import GSAP from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

GSAP.registerPlugin(ScrollTrigger)

/**
 * Takes the number   in $element and counts up towards it
 */
export default (selector, { language }) => {

  document.querySelectorAll(selector).forEach($element => {
    let targetCount
    let isDecimal = false
    let decimalSeparator = language === 'de' ? ',' : '.'

    if ($element.innerText.indexOf(decimalSeparator) !== -1) {
      isDecimal = true
      let innerText = $element.innerText

      if (decimalSeparator !== '.') {
        innerText = innerText.replace(',', '.')
      }

      targetCount = parseFloat(innerText)
    } else {
      targetCount = parseInt($element.innerText, 10)
    }
    let data = {
      var: 0
    }

    GSAP.timeline({
      scrollTrigger: {
        trigger: $element,
        start: 'top bottom-=250',
      }
    })
      .to(data, {
        var: targetCount,
        duration: 2,
        onUpdate() {
          let value
          if (isDecimal) {
            value = data.var.toPrecision(3)
            if (decimalSeparator !== '.') {
              value = value.toString().replace('.', ',')
            }
          } else {
            value = data.var.toFixed()
          }
          $element.innerText = value
        }
      })
  })
}
