const MIDDLE_MOUSE_BUTTON = 1

let plausible

export function watchDownloadLinks(plausibleInstance) {
  plausible = plausibleInstance
  document.addEventListener('click', handleDownloadLinks)
  document.addEventListener('auxclick', handleDownloadLinks)
}

export function unwatchDownloadLinks() {
  document.removeEventListener('click', handleDownloadLinks)
  document.removeEventListener('auxclick', handleDownloadLinks)
  plausible = null
}

function handleDownloadLinks(event) {
  if (event.type === 'auxclick' && event.button !== MIDDLE_MOUSE_BUTTON) {
    return
  }

  let link = getLinkEl(event.target)
  let hrefWithoutQuery = link && link.href && link.href.split('?')[0]

  if (isDownloadToTrack(hrefWithoutQuery)) {
    return sendLinkClickEvent(event, link, { name: 'File Download', props: { url: hrefWithoutQuery } })
  }
}

function getLinkEl(link) {
  while (link && (typeof link.tagName === 'undefined' || !isLink(link) || !link.href)) {
    link = link.parentNode
  }
  return link
}

function isLink(element) {
  return element && element.tagName && element.tagName.toLowerCase() === 'a'
}

function shouldFollowLink(event, link) {
  // If default has been prevented by an external script, Plausible should not intercept navigation.
  if (event.defaultPrevented) { return false }

  let targetsCurrentWindow = !link.target || link.target.match(/^_(self|parent|top)$/i)
  let isRegularClick = !(event.ctrlKey || event.metaKey || event.shiftKey) && event.type === 'click'
  return targetsCurrentWindow && isRegularClick
}

function sendLinkClickEvent(event, link, eventAttrs) {
  let followedLink = false

  function followLink() {
    if (!followedLink) {
      followedLink = true
      window.location = link.href
    }
  }

  if (shouldFollowLink(event, link)) {
    let attrs = { props: eventAttrs.props, callback: followLink }
    plausible.trackEvent(eventAttrs.name, attrs)
    setTimeout(followLink, 1000)
    event.preventDefault()
  } else {
    let attrs = { props: eventAttrs.props }
    plausible.trackEvent(eventAttrs.name, attrs)
  }
}

const fileTypesToTrack = [ 'pdf', 'xlsx', 'docx', 'txt', 'rtf', 'csv', 'exe', 'key', 'pps', 'ppt', 'pptx', '7z', 'pkg', 'rar', 'gz', 'zip', 'avi', 'mov', 'mp4', 'mpeg', 'wmv', 'midi', 'mp3', 'wav', 'wma' ]

function isDownloadToTrack(url) {
  if (!url) {
    return false
  }

  let fileType = url.split('.').pop()
  return fileTypesToTrack.some((fileTypeToTrack) => fileTypeToTrack === fileType)
}

export default {
  watch: watchDownloadLinks,
  unwatch: unwatchDownloadLinks
}
