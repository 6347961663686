import GSAP from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

GSAP.registerPlugin(ScrollTrigger)

export default (selector) => {
  let $elements = document.querySelectorAll(selector)

  if ($elements.length === 0) {
    return
  }

  for (let $el of $elements) {
    const $shields        = Array.from($el.querySelectorAll('[data-shield]'))
    const scrollTrigger = {
      trigger: $el,
      start: 'bottom bottom',
    }


    GSAP.set($shields, {
      transformOrigin: 'center center',
    })

    GSAP.timeline({ scrollTrigger })
      .from($shields,{
        delay: 0.25,
        scale: 0,
        duration: 0.25,
        stagger: 0.05,
        ease: 'easeInOut',
      })
      .to($shields, {
        scale: 0.75,
        duration: 0.5,
        repeat: -1,
        yoyo: true,
        ease: 'easeInOut',
        stagger: 0.05,
      });
  }
}
