import GSAP from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

GSAP.registerPlugin(ScrollTrigger)

export default (selector) => {
  let $elements = document.querySelectorAll(selector)

  if ($elements.length === 0) {
    return
  }

  for (let $el of $elements) {
    let $circles  = $el.querySelectorAll('.circles')
    let $icon     = $el.querySelector('.icon')
    $circles      = Array.from($circles)

    GSAP.set($circles, {
      opacity: 0,
      attr: {
        r: 0,
      },
    })

    GSAP.set($icon, {
      transformOrigin: '17px 17px',
      transform: 'translate(111px, 111px)',
    })

    for (let $circle of $circles) {
      circle($circle, 1.5, $circles.indexOf($circle) / 6, 100,0.8)
    }
  }
}

/**
 * @param $el       Element to animate, must be <circle>
 * @param duration  Overall duration of the animation
 * @param delay     Animation delay
 * @param radius    Target radius
 * @param opacity   Target opacity
 * @returns {gsap.core.Timeline}
 */
export const circle = ($el, duration, delay, radius, opacity) => {
  return GSAP.timeline({
    delay: delay,
    repeat: -1,
    defaults: {
      ease: 'power4.out'
    },
  })
    .fromTo($el, {
      attr: { 
        r: 0,
      }
    }, {
      attr: { 
        r: radius,
      },
      duration: duration,
    })
    .to($el, {
      opacity: opacity,
      duration: duration,
    }, 0)
    .to($el, {
      opacity: 0,
      duration: duration,
    }, duration / 3)
}
