import GSAP from 'gsap'
import {RoughEase} from 'gsap/EasePack'
import ScrollTrigger from 'gsap/ScrollTrigger'

GSAP.registerPlugin(ScrollTrigger)

export default (selector) => {
  let $elements = document.querySelectorAll(selector)

  if ($elements.length === 0) {
    return
  }

  for (let $el of $elements) {
    const $alarmBell   = $el.querySelector('[data-alarm-bell]')
    const $silentBells = $el.querySelectorAll('[data-silent-bell]')
    const $soundWaves  = $el.querySelectorAll('[data-sound-wave]')
    const scrollTrigger = {
      trigger: $el,
      start: 'bottom bottom',
    }

    // GSAP.set($alarmBell, {
    //   transformOrigin: '50% 0',
    // })

    GSAP.timeline({ scrollTrigger })
      .fromTo($alarmBell, {
        rotate: '-5deg',
      }, {
        rotate: '5deg',
        yoyo: true,
        duration: 0.8,
        repeat: -1,
        ease: RoughEase.ease.config({
          strength: 1,
          points: 10,
          randomize: false
        })
      })

    for (let $soundWave of $soundWaves) {
      GSAP.timeline({ scrollTrigger })
        .fromTo($soundWave, {
          opacity: 1,
        }, {
          x: $soundWave.dataset.soundWave === 'left' ? '-=30px' : '+=30px',
          opacity: 0,
          repeat: -1,
          duration: 1.5,
          ease: 'easeOut',
        })
    }

    for (let $bell of $silentBells) {
      GSAP.timeline({ scrollTrigger })
        .to($bell,{
          y: (Math.random() * 100 > 50) ? '+=4px' : '-=4px',
          duration: 1,
          delay: 'random(0.1, 0.2, 0.3, 0.4, 0.5)',
          repeat: -1,
          yoyo: true,
          ease: 'linear',
        })
      }
    }
}
