import GSAP from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

GSAP.registerPlugin(ScrollTrigger)

export default (selector) => {
  let $elements = document.querySelectorAll(selector)

  if ($elements.length === 0) {
    return
  }

  for (let $el of $elements) {
    const $flashes        = Array.from($el.querySelectorAll('[data-flash]'))
    const scrollTrigger = {
      trigger: $el,
      start: 'bottom bottom',
    }


    GSAP.set($flashes, {
      transformOrigin: 'center center',
    })

    GSAP.timeline({ scrollTrigger })
      // Initial scale-in animation
      .from($flashes, {
        scale: 0,
        duration: 0.15,
        ease: 'step',
      })
      // Continuous jiggle (left-right motion at the original position)
      .to($flashes, {
        x: '+=1',  // Move 4px to the right relative to the current position
        duration: 0.1,
        repeat: -1,
        yoyo: true,  // Moves back and forth (left-right)
        ease: 'step',
        stagger: 0.02,
      });
  }
}
