import GSAP from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

GSAP.registerPlugin(ScrollTrigger)

const BACKGROUND_DEFAULT    = '#FFFFFF'
const BACKGROUND_HIGHLIGHT  = '#E1E8F3'

function highlightTableRow($element) {
  return GSAP.timeline()
    .to($element.querySelector('.background use'), {
      fill: BACKGROUND_HIGHLIGHT,
      duration: 0.8,
    })
}

function unhighlightTableRow($element) {
  return GSAP.timeline()
    .to($element.querySelector('.background use'), {
      fill: BACKGROUND_DEFAULT,
      duration: 0.8,
    })
}

export default class EventTimeline {

  constructor(selector) {
    this.$container = document.querySelector(selector)
    this.tl         = null
  }

  init() {
    if (!this.$container) {
      return;
    }

    let $timeline = this.$container.querySelector('#event-timeline')
    let $table = this.$container.querySelector('#event-timeline-table')

    GSAP.set($timeline.querySelectorAll('.event'), {
      opacity: 0
    })

    this.tl = GSAP.timeline({
      scrollTrigger: {
        trigger: this.$container,
        start: 'top center',
      }
    })

    let target = document.querySelector('[data-event-timeline-reference]')
    let targetX = target.getBoundingClientRect().left
    let control = document.querySelector('#event-timeline #day-3')
    let controlX = control.getBoundingClientRect().left

    this.tl.fromTo($timeline.querySelector('#timeline'), {
        x: 0,
      }, {
        x: targetX - controlX,
        duration: 2,
      })

      .fromTo($table, {
        y: 50,
        opacity: 0,
      }, {
        opacity: 1,
        y: 0,
        duration: 1,
      }, '<0.5')

      .fromTo($timeline.querySelectorAll('.event'), {
        opacity: 0,
      }, {
        opacity: 1,
        duration: 0.5,
        stagger: {
          each: 1,
          onStart() {
            let target = this._targets[0].dataset.highlightRow
            let $element = document.getElementById(target)
            let tl = highlightTableRow($element)
            tl.add(unhighlightTableRow($element))
          }
        },
      }, '<1')

      $timeline.querySelectorAll('.event').forEach($event => {
        $event.addEventListener('mouseenter', () => {
          let target = $event.dataset.highlightRow
          highlightTableRow(document.getElementById(target))
        })
        $event.addEventListener('mouseleave', () => {
          let target = $event.dataset.highlightRow
          unhighlightTableRow(document.getElementById(target))
        })
      })

    return this.tl
  }

  destroy() {
    if (this.tl) {
      this.tl.clear()
    }
  }
}
