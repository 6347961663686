import GSAP from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

GSAP.registerPlugin(ScrollTrigger)

export default (selector) => {
  let $elements = document.querySelectorAll(selector)

  if ($elements.length === 0) {
    return
  }

  for (let $el of $elements) {
    const $breadcrumb  = $el.querySelector('[data-icon="breadcrumb"]')
    const $decoy       = $el.querySelector('[data-icon="decoy"]')
    const $theShining  = $el.querySelector('[data-the-shining]')
    const pacmanFrames = Array.from($el.querySelectorAll('[data-pacman-frame]'))

    GSAP.set([$breadcrumb, $decoy, $theShining], {
      transformOrigin: '50% 50%',
    })

    GSAP.timeline({
      repeat: -1,
      duration: 2,
    })
      .add(() => {
        GSAP.set(pacmanFrames[0], { opacity: 0 })
        GSAP.set(pacmanFrames[1], { opacity: 1 })
      }, 0)
      .add(() => {
        GSAP.set(pacmanFrames[0], { opacity: 1 })
        GSAP.set(pacmanFrames[1], { opacity: 0 })
      }, 1)

    if ($el.dataset.animationMobile ?? false) {
      const tl = GSAP.timeline({
      })
        .add(fadeInDots(
          `[data-dot="1"],[data-dot="2"],[data-dot="3"],[data-dot="4"],[data-dot="5"]`, { context: $el, stagger: 0.1 }
        ))
        .add(flashIcon($breadcrumb), 0.5)
        .add(fadeInDots(
          `[data-dot="6"],[data-dot="7"]`, { context: $el, stagger: 0.1 }
        ), 0.5)
        .add(flashIcon([$decoy, $theShining]), 0.7)
    } else {
      GSAP.timeline()
        .add(fadeInDots(
          `[data-dot="1"],[data-dot="2"],[data-dot="3"],[data-dot="4"],[data-dot="5"],[data-dot="6"],[data-dot="7"],[data-dot="8"],[data-dot="9"]`, { context: $el }
        ))
        .add(fadeInDots(
          `[data-dot="13"],[data-dot="14"],[data-dot="15"],[data-dot="16"],[data-dot="17"],[data-dot="18"],[data-dot="19"],[data-dot="20"],[data-dot="21"],[data-dot="22"],[data-dot="23"]`, { context: $el }
        ), 0.35)
        .add(flashIcon($breadcrumb), 0.5)
        .add(fadeInDots(
          `[data-dot="10"],[data-dot="11"],[data-dot="12"]`, { context: $el }
        ), '<')
        .add(flashIcon([$decoy, $theShining]), 0.7)
    }
  }
}

const fadeInDots = (selector, { context, stagger }) => {
  return GSAP.fromTo(context.querySelectorAll(selector), {
      opacity: 0,
    }, {
      opacity: (index, $el, _) => ($el.dataset.targetOpacity) ? parseFloat($el.dataset.targetOpacity) : 1.0,
      stagger: stagger ?? 0.05,
      duration: 0.4,
    })
}

const flashIcon = ($el) => {
  return GSAP.fromTo($el,{
    scale: 0.7,
    opacity: 0,
  }, {
    scale: 1,
    opacity: 1,
    duration: 1,
    ease: 'elastic.out(1, 0.3)'
  })
}
