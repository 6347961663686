import GSAP from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

GSAP.registerPlugin(ScrollTrigger)

export default (selector) => {
  const $elements = document.querySelectorAll('[data-parallax]')

  for (let $el of $elements) {
    const distance = $el.dataset.parallaxDistance ?? ScrollTrigger.maxScroll()
    const speed    = $el.dataset.parallaxSpeed ?? 1
    GSAP.timeline({
      scrollTrigger: {
        scrub: true,
      }
    })
      .to($el, {
        y: distance * speed
      })
  }
}
