import GSAP from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

GSAP.registerPlugin(ScrollTrigger)

export default (selector) => {
  const $elements = document.querySelectorAll(selector)

  for (let element of $elements) {
    const $box = element.querySelector('g[data-box="box"]')

    const $euro = element.querySelector('#perks g[data-perk="currency-euro-circle"]')
    const $team = element.querySelector('#perks g[data-perk="team-meeting-chat"]')
    const $laptop = element.querySelector('#perks g[data-perk="laptop-smiley"]')
    const $house = element.querySelector('#perks g[data-perk="house-nature"]')
    const $apple = element.querySelector('#perks g[data-perk="fruit-apple"]')
    const $family = element.querySelector('#perks g[data-perk="family-walk-park"]')

    GSAP.set([ $apple, $family, $laptop, $house, $euro, $team ], { opacity: 0 })

    const pling = ($box, $perk) => {
      return GSAP.timeline({})
        .to($box, {
          y: '-=10',
          ease: 'power4.in',
          duration: 0.15,
        })
        .to($box, {
          y: '+=10',
          duration: 0.1,
        }, '>')
        .to($perk, {
          y: '-=75',
          opacity: 1,
          duration: 0.3,
        }, 0.15)
        .to($perk, {
          y: '-=10',
          duration: 0.6,
        })
        .to($perk, {
          opacity: 0,
          duration: 1,
          y: '-=20'
        }, '<0.5')
    }

    GSAP.timeline({
      repeat: -1,
      repeatDelay: 5,
    })
      .add(pling($box, $euro), 0)
      .add(pling($box, $apple), 1)
      .add(pling($box, $laptop), 2)
      .add(pling($box, $team), 7)
      .add(pling($box, $family), 8)
      .add(pling($box, $house), 9)
  }
}
