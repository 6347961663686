import GSAP from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

GSAP.registerPlugin(ScrollTrigger)

export default (selector) => {
  let $elements = document.querySelectorAll(selector)

  if ($elements.length === 0) {
    return
  }

  for (let $el of $elements) {
    const $outerPath    = $el.querySelector('path[data-outer-path]')
    const $icons        = Array.from($el.querySelectorAll('[data-icon]'))
    const scrollTrigger = {
      trigger: $el,
      start: 'bottom bottom',
    }

    let totalLength = $outerPath.getTotalLength()
    $outerPath.setAttribute('stroke-dasharray', Math.ceil(totalLength))
    $outerPath.setAttribute('stroke-dashoffset', Math.ceil(totalLength))

    GSAP.timeline({ scrollTrigger })
      .to($outerPath, {
        strokeDashoffset: 0,
        duration: 2.5,
        ease: 'easeInOut',
      })

    GSAP.set($icons, {
      transformOrigin: 'center center',
    })

    const iconOrder = [
      'monitor-antenna',
      'saving-bank-cash',
      'water-protection-faucet',
      'organic-plant',
      'renewable-energy-solar-power',
      'hospital-house',
      'truck-cargo',
    ]

    $icons.sort((a, b) => {
      let aPos = iconOrder.indexOf(a.getAttribute('id'))
      let bPos = iconOrder.indexOf(b.getAttribute('id'))
      return (aPos < bPos) ? -1 : 1
    })

    GSAP.timeline({ scrollTrigger })
      .to($icons,{
        scale: 1.15,
        duration: 0.5,
        stagger: 0.21,
        ease: 'easeInOut',
      })
      .to($icons, {
        scale: 1,
        duration: 0.5,
        stagger: 0.21,
        ease: 'easeInOut',
      }, 0.5)
  }
}
