import GSAP from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { circle } from './circles'

GSAP.registerPlugin(ScrollTrigger)

export default (selector) => {
  let $elements = document.querySelectorAll(selector)

  if ($elements.length === 0) {
    return
  }

  for (let $el of $elements) {
    const $copy        = $el.querySelector('[data-kugelblitz-copy]')
    const $svg         = $el.querySelector('[data-kugelblitz-svg]')
    const $lens        = $svg.querySelector('[data-lens]')

    const targets = {
      breadcrumb: $el.querySelector('[data-target="breadcrumb"]'),
      decoy:      $el.querySelector('[data-target="decoy"]'),
    }

    const icons = {
      breadcrumb: $svg.querySelector('[data-icon="breadcrumb"]'),
      decoy:      $svg.querySelector('[data-icon="decoy"]'),
    }

    const circleGroups     = Array.from($svg.querySelectorAll('[data-circles]'))
    let initialLensOpacity = $lens.getAttribute('fill-opacity') ?? 1

    if ($copy) {
      $copy.classList.add('lg:mt-32')
    }

    GSAP.set($lens, { opacity: 0 })
    GSAP.set([icons.breadcrumb, icons.decoy, targets.breadcrumb, targets.decoy], { transformOrigin: '50% 50%' })

    GSAP.timeline({
      scrollTrigger: {
        scrub: true,
        pin: $svg,
        trigger: $el,
        start: 'top top',
        end: 'bottom bottom',
      }
    })
      .to($lens, {
        x: '-=250',
        y: '+=175',
        duration: 8,
      })
      .to($lens, {
        opacity: initialLensOpacity,
        duration: 0.8,
      },0)
      .to($lens, {
        opacity: 0,
        duration: 0.8,
      }, 7.2)

    for (let target in targets) {
      GSAP.timeline({
        scrollTrigger: {
          trigger: targets[target],
          start: 'top 50%',
          toggleActions: 'restart none none reset'
        }
      })
        .add(flash(targets[target]), 0)
        .add(flash(icons[target]), 0)
    }

    for (let $group of circleGroups) {
      const $circles = Array.from($group.querySelectorAll('circle'))

      for (let $circle of $circles) {
        let delay = $circles.indexOf($circle) / 6
        circle($circle, 1.5, delay, 80, 0.8)
      }
    }
  }
}

const flash = ($el) => {
  return GSAP.timeline({
    defaults: {
      ease: 'easeOutExpo',
    }
    })
    .to($el, {
      scale: 1.4,
      duration: 0.3,
    })
    .to($el, {
      scale: 1,
      duration: 0.3,
    })
}
